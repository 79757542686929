"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryListContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const A = __importStar(require("fp-ts/Array"));
const O = __importStar(require("fp-ts/Option"));
const NEA = __importStar(require("fp-ts/NonEmptyArray"));
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const summary_list_item_1 = require("./summary-list-item");
const components_1 = require("client/components");
const context_1 = require("client/modules/booking/booking-wizard/context");
const form_1 = require("client/modules/booking/booking-wizard/form");
const tracking_1 = require("client/tracking");
exports.SummaryListContainer = (0, react_1.memo)(() => {
    const { value, setValue } = (0, context_1.useBookingGlobalForm)();
    const { goTo } = (0, context_1.useBookingWizardNavigation)();
    const { trackGenericEvent } = (0, tracking_1.usePiwikTrackBookingSession)();
    const focusOnRoom = (room) => {
        const step = (0, form_1.isReadonlyBookingRoom)(room) ? 'ending' : 'who';
        goTo(step);
    };
    const onToggled = (focusedRoomIndex) => (toggled) => {
        if (!toggled) {
            return;
        }
        setValue({
            merge: true,
            value: {
                meta: {
                    ...value.meta,
                    focusedRoomIndex,
                },
            },
        });
        focusOnRoom(value.rooms[focusedRoomIndex]);
    };
    const onRemove = (focusedRoomIndex) => () => {
        const variantId = value.rooms[focusedRoomIndex]?.variant?.id;
        const newRooms = (0, function_1.pipe)(A.unsafeDeleteAt(focusedRoomIndex, value.rooms), (0, commons_1.isNil)(variantId)
            ? items => items
            : (0, form_1.rejectBookingRoomsByVariant)(variantId), NEA.fromArray, O.getOrElse(() => NEA.of((0, form_1.createBlankBookingRoom)())));
        const newFocusedRoomIndex = Math.max(0, newRooms.length - 1);
        setValue({
            merge: true,
            value: {
                meta: {
                    ...value.meta,
                    focusedRoomIndex: newFocusedRoomIndex,
                },
                rooms: newRooms,
            },
        });
        trackGenericEvent({
            universe: 'booking',
            place: 'wizard',
            action: 'remove-from-cart',
            totalItemsInBasket: newRooms.length,
        });
        focusOnRoom(newRooms[newFocusedRoomIndex]);
    };
    return ((0, jsx_runtime_1.jsx)(components_1.CleanList, { "$row": false, "$gap": 4, children: value.rooms.map((room, index) => ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(summary_list_item_1.SummaryListItem, { room: room, index: index + 1, active: index === value.meta.focusedRoomIndex, onToggled: onToggled(index), onRemove: onRemove(index) }) }, room.meta.editorId))) }, value.meta.focusedRoomIndex));
});
exports.SummaryListContainer.displayName = 'SummaryListContainer';
