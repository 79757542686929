"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GTMTrackerService = void 0;
const commons_1 = require("@gerber/commons");
class GTMTrackerService {
    constructor() {
        this.trackGenericEvent = ({ name, ...attrs }) => {
            const dto = {
                event: name,
                ...attrs,
            };
            if ((0, commons_1.isDevBuild)()) {
                console.info('Send GTM event:', dto);
            }
            else {
                window.dataLayer?.push(dto);
            }
        };
    }
}
exports.GTMTrackerService = GTMTrackerService;
