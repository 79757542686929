"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardEnterTracker = void 0;
const react_1 = require("react");
const form_1 = require("../form");
const tracking_1 = require("client/tracking");
exports.BookingWizardEnterTracker = (0, react_1.memo)(() => {
    const piwik = (0, tracking_1.usePiwikTrackBookingSession)();
    const gtm = (0, tracking_1.useGTM)();
    const maybePackageGroupId = (0, form_1.useBookingWizardPackageGroupId)();
    (0, react_1.useEffect)(() => {
        piwik.trackGenericEvent({
            universe: 'booking',
            place: 'wizard',
            action: 'enter',
        });
        gtm.trackGenericEvent({
            name: 'enter_wizard',
            package_group_id: maybePackageGroupId,
        });
    }, []);
    return null;
});
exports.BookingWizardEnterTracker.displayName = 'BookingWizardEnterTracker';
