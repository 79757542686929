"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppConfigV = exports.AppSentryConfigV = exports.AppGerberConfigV = exports.AppGTMConfigV = exports.AppPiwikConfigV = void 0;
const zod_1 = require("zod");
const commons_1 = require("@gerber/commons");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const sdk_storyblok_1 = require("@gerber/sdk-storyblok");
exports.AppPiwikConfigV = zod_1.z.object({
    accountId: zod_1.z.string(),
});
exports.AppGTMConfigV = zod_1.z.object({
    accountId: zod_1.z.string(),
});
exports.AppGerberConfigV = sdk_gerber_1.SdkGerberClientConfigV.omit({
    oneId: true,
    lang: true,
}).extend({
    defaultOneId: zod_1.z.string(),
});
exports.AppSentryConfigV = zod_1.z.object({
    dsn: zod_1.z.string(),
});
exports.AppConfigV = zod_1.z.object({
    env: commons_1.AppEnvV,
    gerber: exports.AppGerberConfigV,
    storyblok: sdk_storyblok_1.SdkStoryblokHydrateConfigV,
    basePrefix: zod_1.z.string(),
    piwik: exports.AppPiwikConfigV.optional(),
    gtm: exports.AppGTMConfigV.optional(),
    sentry: exports.AppSentryConfigV.optional(),
});
