"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelRequestNowCTA = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const ui_1 = require("client/ui");
const parts_1 = require("client/modules/landing-page/parts");
const i18n_1 = require("client/i18n");
const tracking_1 = require("client/tracking");
const SbHotelRequestNowCTA = ({ link, oneId, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const piwik = (0, tracking_1.usePiwik)();
    const onClick = () => {
        piwik.trackGenericEvent({
            universe: 'landing-page',
            place: 'hotel-card',
            action: 'request-now',
            oneId,
        });
    };
    return ((0, jsx_runtime_1.jsx)(parts_1.SbLink, { link: link, ...props, children: (0, jsx_runtime_1.jsxs)(ui_1.Button, { "$kind": "outline", as: "span", onClick: onClick, children: [pack.landingPage.hotel.requestNow, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, {}) })] }) }));
};
exports.SbHotelRequestNowCTA = SbHotelRequestNowCTA;
