"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelCardToolbar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const commons_front_1 = require("@gerber/commons-front");
const buttons_1 = require("../buttons");
const storyblok_hotel_card_toolbar_styled_1 = require("./storyblok-hotel-card-toolbar.styled");
exports.SbHotelCardToolbar = (0, forms_1.controlled)(({ item, showOffersCTA, control: { bind } }) => {
    const { anim } = (0, commons_front_1.useInitialAnimatedElement)();
    return ((0, jsx_runtime_1.jsxs)(storyblok_hotel_card_toolbar_styled_1.SbHotelCardToolbarHolder, { children: [(0, jsx_runtime_1.jsx)(buttons_1.SbHotelBookCTA, { oneId: item.oneId, ...anim('animate-fade-in-from-down') }), item.requestNowLink && ((0, jsx_runtime_1.jsx)(buttons_1.SbHotelRequestNowCTA, { oneId: item.oneId, link: item.requestNowLink, ...anim('animate-fade-in-from-down') })), showOffersCTA && ((0, jsx_runtime_1.jsx)(buttons_1.SbHotelDiscoverOffersCTA, { ...bind.entire(), ...anim('animate-fade-in-from-down') }))] }));
});
