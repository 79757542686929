"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DE_LANG_PACK = exports.DE_BOOKING_ROOMS_SORT = exports.DE_LANG_GENDERS = void 0;
exports.DE_LANG_GENDERS = {
    family: 'Familie',
    male: 'Herr',
    female: 'Frau',
};
exports.DE_BOOKING_ROOMS_SORT = {
    recommended: 'Empfohlene Zimmer',
    'name-az': 'Alphabetisch sortieren',
    'rate:asc': 'Preis aufsteigend',
    'rate:desc': 'Preis absteigend',
};
exports.DE_LANG_PACK = {
    notifications: {
        error: 'Es ist ein Fehler aufgetreten!',
        urlCopied: 'Link wurde in die Zwischenablage kopiert!',
    },
    common: {
        close: 'Schließen',
        continue: 'Weiter',
        website: 'Webseite',
        exit: 'Beenden',
        edit: 'Bearbeiten',
        more: 'Mehr',
        clear: 'Löschen',
        remove: 'Entfernen',
        scroll: 'Scrollen',
        next: 'Weiter',
        back: 'Zurück',
        select: 'Auswählen',
        selected: 'Ausgewählt',
        details: 'Details',
        results: '%{total} Ergebnisse',
        moreInformation: 'Weitere Informationen',
        roomCategory: 'Zimmerkategorie',
        packageGroup: 'Pauschale',
        includeTax: 'inkl. MwSt. zzgl. Ortstaxe (€ 2,00 p.P. und ÜN. ab 15 Jahren)',
        payNow: 'Zahlen',
        fromPrice: 'ab %{price}',
        showMore: 'Mehr anzeigen',
        showLess: 'Weniger anzeigen',
        nights: '%{nights} Nächte',
        roomIn360: 'Raum in 360°',
        variantOffer: 'Angebot',
        contact: 'Kontakt',
    },
    gender: exports.DE_LANG_GENDERS,
    validation: {
        required: 'Dieses Feld ist erforderlich',
        incorrect: 'Falscher Feldwert',
    },
    placeholders: {
        chooseValue: 'Wert auswählen',
        enterValue: 'Wert eingeben...',
    },
    booking: {
        title: 'Hotelbuchung',
        wizard: {
            share: {
                shareYourProgress: 'Buchung teilen',
                shareAndContinue: 'Teilen und fortfahren',
                shareCopy: 'Formularkopie teilen',
            },
            summary: {
                title: 'Zusammenfassung',
                room: 'Zimmer #%{index}',
                roomShort: '#%{index}',
                adults: '%{total}x Erwachsene',
                kids: '%{total}x Kind (Alter %{age})',
                cancellation: 'Stornobedingungen',
                nights: (nights) => `${nights} Nächt${nights === 1 ? '' : 'e'}`,
            },
            price: {
                from: 'Preis ab',
                exact: 'Preis',
                total: 'Gesamtpreis ab',
                totalExact: 'Gesamtpreis',
                deposit: 'Anzahlung',
            },
            contact: {
                header: 'Check-Out',
                roomNo: 'Zimmer #%{index}',
                adultNo: 'Erwachsene #%{index}',
                childNo: 'Kinder #%{index} (%{age} Jahre)',
                personalData: {
                    header: 'Persönliche Daten',
                    gender: {
                        label: 'Anrede',
                        placeholder: 'Wählen Sie Anrede',
                    },
                    title: {
                        label: 'Titel',
                        placeholder: 'Titel eingeben',
                    },
                    firstName: {
                        label: 'Vorname',
                        placeholder: 'Bitte Vornamen eingeben',
                    },
                    lastName: {
                        label: 'Familienname, Nachname',
                        placeholder: 'Nachnamen eingeben',
                    },
                    birthDate: {
                        label: 'Geburtsdatum',
                        placeholder: 'Geburtsdatum eingeben',
                    },
                    email: {
                        label: 'Email',
                        placeholder: 'Email eingeben',
                    },
                    phone: {
                        label: 'Telefonnummer',
                        placeholder: 'Geben Sie Ihre Tel-Nr. ein',
                    },
                    address: {
                        street: {
                            label: 'Straße und Hausnummer',
                            placeholder: 'Geben Sie die Straße ein',
                        },
                        additionalInfo: {
                            label: 'Weitere Adressinformationen',
                            placeholder: 'Geben Sie weitere Adressinformationen ein',
                        },
                        zipCode: {
                            label: 'PLZ',
                            placeholder: 'Bitte Postleitzahl eingeben',
                        },
                        city: {
                            label: 'Stadt',
                            placeholder: 'Stadt betreten',
                        },
                        country: {
                            label: 'Land',
                            placeholder: 'Land auswählen',
                        },
                    },
                },
                peopleInfo: {
                    title: 'Wer reist mit Ihnen?',
                    firstName: {
                        label: 'Vorname',
                        placeholder: 'Vorname - Gast',
                    },
                    lastName: {
                        label: 'Nachname',
                        placeholder: 'Nachname - Gast',
                    },
                    birthDate: {
                        label: 'Geburtsdatum',
                        placeholder: 'Geburtsdatum - Gast',
                    },
                },
                additionalInfo: {
                    title: 'Ihre persönliche Nachricht an uns',
                    subtitle: 'Haben Sie ein Anliegen, das Sie uns bereits vor Anreise mitteilen möchten?',
                    placeholder: 'Hier eine Nachricht hinterlassen...?',
                },
            },
            terms: {
                title: 'Unsere Buchungsbedingungen',
                agree: {
                    generalTerms: {
                        title: 'Ich stimme den %{anchor} zu.',
                        anchor: 'Allgemeinen Geschäftsbedingungen',
                    },
                    securityTerms: {
                        title: 'Ich stimme der %{anchor} zu.',
                        anchor: 'Datenschutzerklärung',
                    },
                },
            },
            start: {
                header: 'Anzahl der Gäste',
                hint: 'Sie können weitere Zimmer später noch hinzufügen.',
                cta: {
                    bookNow: 'Jetzt buchen',
                },
                form: {
                    adults: {
                        label: 'Erwachsene:',
                        tooltip: 'Ab %{age} Jahren.',
                    },
                    children: {
                        label: 'Kinder:',
                        tooltip: 'Bitte geben Sie das Alter des Kindes zum Zeitpunkt des Aufenthaltes an',
                    },
                    age: {
                        label: 'Jahre',
                    },
                },
                notification: {
                    selectAnotherRoom: 'Bitte wählen Sie aus, wer im <strong>Zimmer #%{roomIndex}</strong> übernachten wird',
                    package: '<strong>Bitte beachten Sie.</strong> Sie haben eine Pauschale gewählt, die an bestimmte Zeiträume und gegebenenfalls Mindestbelegungen gebunden sind. Bei Fragen wenden Sie sich gerne telefonisch an uns.',
                },
            },
            validation: {
                missingChildrenAge: 'Bitte geben Sie das Alter Ihrer Kinder an.',
            },
            date: {
                header: 'Wählen Sie Ihren An- und Abreisetag',
                minStay: 'min. Aufenthalt > %{} Übernachtungen',
                notification: {
                    package: '<strong>Bitte beachten Sie.</strong> Sie haben eine Pauschale gewählt, die an bestimmte Zeiträume und gegebenenfalls Mindestbelegungen gebunden sind. Bei Fragen wenden Sie sich gerne telefonisch an uns.',
                },
            },
            room: {
                header: 'Wählen Sie Ihr Zimmer aus',
                sort: exports.DE_BOOKING_ROOMS_SORT,
            },
            ending: {
                header: 'Super',
                subtitle: '<strong>Wir haben alle Infos, die wir für Ihre Buchung benötigen.</strong> Entscheiden Sie jetzt, ob Sie weitere Angebote buchen möchten oder Ihren Buchungsprozess abschließen möchten.',
                addMoreRooms: 'Möchten Sie noch weitere Leistungen buchen?',
                cta: {
                    completeBooking: 'Buchung abschließen',
                    bookAnotherRoom: 'Ein weiteres Zimmer buchen',
                },
            },
            sidebar: {
                alerts: {
                    moreThanOneRoomSelected: 'Sie haben %{count} Zimmer ausgewählt!',
                },
                cta: {
                    website: 'Hotel Website',
                    phone: 'Telefon',
                    contact: 'Anfragen',
                },
                payment: {
                    title: 'Zahlungsmöglichkeiten',
                },
                placeholder: {
                    title: 'Hier werden alle relevanten Buchungsinformationen für Sie zusammengefasst.',
                    subtitle: 'Erhalten Sie hier zusätzliche Informationen zu den einzelnen Schritten sowie den Überblick über Ihren Buchungsforschritt.',
                },
                bookingInfo: {
                    title: 'Buchungsinfos',
                },
            },
            footer: {
                cta: {
                    showDetails: 'Details anzeigen',
                    hideDetails: 'Details ausblenden',
                },
            },
        },
        confirm: {
            success: {
                title: 'Zahlung erfolgreich abgeschlossen / Buchung ist erfolgt',
                description: '<p>Wir freuen uns, Ihnen mitteilen zu können, dass Ihre Buchung erfolgreich abgeschlossen wurde! Vielen Dank für Ihre Reservierung.</p> <p>Ihr Zimmer ist nun reserviert und wir freuen uns darauf, Sie bei uns begrüßen zu dürfen.</p> <p>Für weitere Fragen oder Anliegen stehen wir Ihnen gerne zur Verfügung. Bitte zögern Sie nicht, uns zu kontaktieren.</p>',
                nextSteps: 'Ihre nächsten Schritte!',
                shareWithYourFamily: 'Teilen Sie es mit Ihren Mitreisenden, Ihrer Familie oder Ihren Freunden.',
            },
            error: {
                title: 'Zahlung abgebrochen oder Fehler bei der Zahlungsabwicklung',
                description: '<p>Es tut uns leid, Ihnen mitteilen zu müssen, dass Ihre Buchung nicht erfolgreich abgeschlossen werden konnte. Die Zahlung wurde abgebrochen oder ist fehlgeschlagen. Wir bedauern eventuelle Unannehmlichkeiten und möchten Sie darauf hinweisen, dass Ihr Zimmer derzeit nicht reserviert ist.</p> <br /> <p>Der Fehler bei der Zahlungsabwicklung kann unterschiedliche Ursachen haben. Bitte kontaktieren Sie uns bei Fragen. Überprüfen Sie bitte auch Ihre Zahlungsinformationen und versuchen Sie es erneut, oder kontaktieren Sie Ihre Bank, um mögliche Fragen zu klären.</p>',
            },
            cancel: {
                title: 'Zahlung abgebrochen oder Fehler bei der Zahlungsabwicklung',
                description: '<p>Es tut uns leid, Ihnen mitteilen zu müssen, dass Ihre Buchung nicht erfolgreich abgeschlossen werden konnte. Die Zahlung wurde abgebrochen oder ist fehlgeschlagen. Wir bedauern eventuelle Unannehmlichkeiten und möchten Sie darauf hinweisen, dass Ihr Zimmer derzeit nicht reserviert ist.</p> <br /> <p>Der Fehler bei der Zahlungsabwicklung kann unterschiedliche Ursachen haben. Bitte kontaktieren Sie uns bei Fragen. Überprüfen Sie bitte auch Ihre Zahlungsinformationen und versuchen Sie es erneut, oder kontaktieren Sie Ihre Bank, um mögliche Fragen zu klären.</p>',
            },
        },
    },
    landingPage: {
        footer: {
            media: 'Social Media',
            contact: 'Kontakt',
        },
        hotel: {
            bookRoom: 'Jetzt buchen',
            discoverOffers: 'Angebote entdecken',
            requestNow: 'Anfragen',
        },
        offer: {
            bookNow: 'Jetzt buchen',
        },
    },
    routeError: {
        notFound: 'Nicht gefunden',
    },
};
