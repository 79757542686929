"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardProgressTracker = void 0;
const O = __importStar(require("fp-ts/Option"));
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const form_1 = require("../form");
const context_1 = require("../context");
const tracking_1 = require("client/tracking");
exports.BookingWizardProgressTracker = (0, react_1.memo)(() => {
    const depthCache = (0, react_1.useRef)({});
    const piwik = (0, tracking_1.usePiwikTrackBookingSession)();
    const navigation = (0, context_1.useBookingWizardNavigation)();
    const globalValue = (0, context_1.useBookingGlobalForm)();
    const { session } = (0, context_1.useSubscribeBookingWizardSessionContent)();
    const trackStep = (step) => {
        const totalItemsInBasket = globalValue.value.rooms.length;
        const stepCacheEntry = depthCache.current[totalItemsInBasket];
        if (!!stepCacheEntry?.all || !!stepCacheEntry?.step?.[step]) {
            return;
        }
        piwik.trackGenericEvent({
            universe: 'booking',
            place: 'wizard',
            action: 'booking-depth',
            totalItemsInBasket,
            wizardStep: step,
        });
        (depthCache.current[totalItemsInBasket] ||= {}).step = {
            ...depthCache.current?.[totalItemsInBasket]?.step,
            [step]: true,
        };
    };
    // restore state after restore session, do not track again already filled rooms
    (0, commons_front_1.useInstantAfterMount)(() => {
        globalValue.value.rooms.forEach((room, index) => {
            if ((0, form_1.areCriticalBookingRoomValuesFilled)(room)) {
                depthCache.current[index + 1] = {
                    all: true,
                };
            }
        });
    });
    // track first step after receiving session
    (0, react_1.useEffect)(() => {
        if (O.isSome(session)) {
            trackStep('who');
            trackStep(navigation.step);
        }
    }, [session, navigation.step]);
    return null;
});
exports.BookingWizardProgressTracker.displayName = 'BookingWizardProgressTracker';
