"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const app_html_header_1 = require("./app-html-header");
const providers_1 = require("./providers");
const app_router_1 = require("./app-router");
const fonts_1 = require("./fonts");
const tracking_1 = require("./tracking");
const AppContainer = (props) => ((0, jsx_runtime_1.jsxs)(providers_1.AppProviders, { ...props, children: [(0, jsx_runtime_1.jsx)(tracking_1.PiwikHeadScript, {}), (0, jsx_runtime_1.jsx)(tracking_1.GTMHeadScript, {}), (0, jsx_runtime_1.jsx)(commons_front_1.GlobalNormalize, {}), (0, jsx_runtime_1.jsx)(app_html_header_1.AppHTMLHeader, {}), (0, jsx_runtime_1.jsx)(fonts_1.AppFonts, {}), (0, jsx_runtime_1.jsx)(app_router_1.AppRouter, {})] }));
exports.AppContainer = AppContainer;
