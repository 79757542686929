"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbPackageGroupToolbar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const function_1 = require("fp-ts/function");
const T = __importStar(require("fp-ts/Task"));
const commons_1 = require("@gerber/commons");
const wouter_1 = require("wouter");
const storyblok_package_group_toolbar_styled_1 = require("./storyblok-package-group-toolbar.styled");
const modals_1 = require("client/modules/booking/booking-wizard/modals");
const hooks_1 = require("client/hooks");
const tracking_1 = require("client/tracking");
const ui_1 = require("client/ui");
const SbPackageGroupToolbar = ({ packageGroup, oneId, }) => {
    const piwik = (0, tracking_1.usePiwik)();
    const [, navigate] = (0, wouter_1.useLocation)();
    const sitemap = (0, hooks_1.useSitemap)();
    const detailsModal = (0, modals_1.useBookingPackageGroupDetailsModal)();
    const onItemSelected = () => {
        piwik.trackGenericEvent({
            universe: 'landing-page',
            place: 'hotel-card',
            action: 'book-package',
            packageGroupId: packageGroup.id,
            oneId,
        });
        navigate(sitemap.wizard.root({
            oneId,
            search: {
                ...(0, commons_1.decodeSearchParams)(document.location.href),
                packageGroupId: packageGroup.id,
                skipWelcome: true,
            },
        }));
    };
    const onShowDetails = (0, function_1.pipe)(() => {
        piwik.trackGenericEvent({
            universe: 'landing-page',
            place: 'hotel-card',
            action: 'show-details',
            result: 'click',
            packageGroupId: packageGroup.id,
            oneId,
        });
    }, T.fromIO, T.chain(() => detailsModal.showAsOptional({
        packageGroup,
        renderCta: ({ onAfterChoose }) => ((0, jsx_runtime_1.jsx)(ui_1.BookNowButton, { onClick: onAfterChoose })),
    })), (0, commons_1.tapTaskOption)(onItemSelected));
    return ((0, jsx_runtime_1.jsxs)(storyblok_package_group_toolbar_styled_1.SbPackageGroupToolbarHolder, { children: [(0, jsx_runtime_1.jsx)(ui_1.DetailsButton, { onClick: () => void onShowDetails() }), (0, jsx_runtime_1.jsx)(ui_1.BookNowButton, { onClick: onItemSelected })] }));
};
exports.SbPackageGroupToolbar = SbPackageGroupToolbar;
