"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PiwikTrackerService = void 0;
const commons_1 = require("@gerber/commons");
class PiwikTrackerService {
    constructor() {
        this.trackGenericEvent = ({ place, universe, ...attrs }) => {
            const dto = {
                event: 'custom_event',
                universe,
                place,
                ...attrs,
            };
            if ((0, commons_1.isDevBuild)()) {
                console.info('Send piwik event:', dto);
            }
            else {
                window.dataLayer?.push(dto);
            }
        };
    }
}
exports.PiwikTrackerService = PiwikTrackerService;
